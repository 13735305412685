<template>
  <v-col cols="3">
    <v-text-field label="Название" v-model="name" @keyup.enter="submit" @keyup.esc="$emit('hide')" outlined dense hide-details clearable autofocus>
      <template v-slot:append-outer>
        <v-btn icon :disabled="name.length === 0" @click="submit">
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AddInfomodelForm',
  data() {
    return {
      name: '',
      isLoading: false,
    };
  },
  methods: {
    async submit() {
      this.isLoading = true;

      try {
        const { id, name } = await this.CREATE_ATTRIBUTE_GROUP({
          name: this.name,
        });
        this.$notify.showMessage({ message: `Инфомодель «${name}» добавлена`, color: 'success' });
        this.$router.push(`/infomodels/${id}`);
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
    ...mapActions('attribute_groups', ['CREATE_ATTRIBUTE_GROUP']),
  },
};
</script>
