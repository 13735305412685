<template>
  <v-row>
    <v-col cols="4">
      <QueryFilterObserver v-bind="filters.autocomplete" />
    </v-col>
  </v-row>
</template>

<script>
import QueryFilterObserver from '@/components/QueryFilterObserver';

export default {
  components: {
    QueryFilterObserver,
  },
  computed: {
    filters() {
      return {
        autocomplete: {
          component: 'v-text-field',
          name: 'autocomplete',
          attrs: {
            label: 'Поиск по инфомоделям',
            clearable: true,
          },
        },
      };
    },
  },
};
</script>
