<template>
  <div>
    <v-col cols="12">
      <v-row align="baseline">
        <h1>Инфомодели <span class="subtitle-1">{{ attributeGroupsCount }} </span></h1>

        <AddInfomodelButton @click="AddInfomodelFormIsShown = !AddInfomodelFormIsShown" />
        <AddInfomodelForm v-if="AddInfomodelFormIsShown" @hide="AddInfomodelFormIsShown = false" />
      </v-row>
    </v-col>
    <v-col cols="12">
      <InfomodelsFilter />
    </v-col>
    <v-col class="my-15 text-center" cols="12" v-if="isLoading">
      <v-progress-circular size="42" indeterminate />
    </v-col>
    <v-col cols="12" v-else>
      <InfomodelTable />
    </v-col>
    <v-row justify="center" v-if="pageCount > 1">
      <v-col cols="3">
        <v-pagination v-model="page" :length="pageCount" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import InfomodelTable from '@/components/InfomodelTable/InfomodelTable';
import AddInfomodelButton from '@/components/InfomodelTable/AddInfomodelButton';
import AddInfomodelForm from '@/components/InfomodelTable/AddInfomodelForm';
import InfomodelsFilter from '@/components/InfomodelTable/InfomodelsFilter';

import PaginationMixin from '@/mixins/PaginationMixin';

export default {
  name: 'Infomodels',
  components: {
    InfomodelTable,
    AddInfomodelButton,
    AddInfomodelForm,
    InfomodelsFilter,
  },
  mixins: [PaginationMixin],
  data() {
    return {
      itemsPerPage: 24,
      isLoading: false,
      AddInfomodelFormIsShown: false,
    };
  },
  metaInfo() {
    return {
      title: 'Инфомодели — Инвентарь',
    };
  },
  computed: {
    ...mapState('attribute_groups', ['attributeGroupsCount']),
    pageCount() {
      return Math.ceil(this.attributeGroupsCount / this.itemsPerPage);
    },
  },
  watch: {
    '$route.query': {
      deep: true,
      handler: debounce(async function (params) {
        this.fetchAttributeGroups(params);
      }, 400),
    },
  },
  created() {
    this.fetchAttributeGroups();
  },
  methods: {
    ...mapActions('attribute_groups', ['FETCH_ATTRIBUTE_GROUPS']),
    async fetchAttributeGroups(params = {}) {
      this.isLoading = true;

      try {
        await this.FETCH_ATTRIBUTE_GROUPS({ ...params, page_size: this.itemsPerPage });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = e.isAxiosCancelError;
        if (!e.isAxiosCancelError) this.$notify.showErrorMessage(e.response);
        if (!e.isAxiosError && !e.isAxiosCancelError) throw e;
      }
    },
  },
};
</script>
