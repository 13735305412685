<template>
  <v-simple-table class="infomodel-table" v-if="attributeGroupsCount">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Номер</th>
          <th class="text-left">Название</th>
          <th class="text-left">Действия</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in attributeGroups" :key="item.id">
          <td v-text="item.id" />
          <td>
            <router-link :to="getLink(item)">{{ item.name }}</router-link>
          </td>
          <td>
            <DeleteInfomodelButton :id="item.id" />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <div v-else>Кажется тут пусто ;(</div>
</template>

<script>
import { mapState } from 'vuex';
import DeleteInfomodelButton from '@/components/InfomodelTable/DeleteInfomodelButton';

export default {
  name: 'InfomodelTable',
  components: {
    DeleteInfomodelButton,
  },
  computed: {
    ...mapState('attribute_groups', ['attributeGroups', 'attributeGroupsCount']),
  },
  methods: {
    getLink({ id }) {
      return `/infomodels/${id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.infomodel-table {
  width: 100%;
}
</style>
