<template>
  <v-tooltip bottom open-delay="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="success" v-bind="attrs" v-on="on" @click="$emit('click')"><v-icon>mdi-plus</v-icon></v-btn>
    </template>
    <span>Добавить инфомодель</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'AddInfomodelButton',

};
</script>
